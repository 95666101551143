import { cx } from "@linaria/core";
import { BaseHTMLAttributes, FC, memo } from "react";
import { Link } from "@/ui/Link";
import { cssIsSlider, StyledImageContainer } from "./StyledImageContainer";
export type ProductImageContainerPropsType = {
  path: string | null;
  name?: string;
  isSlider?: boolean;
  disabledLink?: boolean;
};
export const ImageContainer: FC<ProductImageContainerPropsType & BaseHTMLAttributes<HTMLDivElement>> = memo(({
  children,
  path,
  name = "",
  isSlider,
  disabledLink = false,
  className,
  ...props
}) => {
  return <StyledImageContainer {...props} className={cx(!!isSlider && cssIsSlider, className)}>
        {!isSlider && <Link href={path || ""} title={!disabledLink ? name : undefined} scroll textIsHidden>
            {!disabledLink && name}
          </Link>}
        {children}
      </StyledImageContainer>;
});
ImageContainer.displayName = "ImageContainer";